// Imports => MOBX
import { observable, computed, makeObservable, action, toJS } from 'mobx';
import { AcBuildURLSearchParams } from '@utils';

let app = {};

const LIMIT = 3;

export const DEFAULT_SEARCH_QUERY = {
  _limit: LIMIT,
};

const DEFAULT_QUERY = {};

if (process.env.API_URL_COMMONGROUND_ORGANIZATION_OIN) {
}

export class DocumentsStore {
  constructor(store) {
    makeObservable(this);
    app.store = store;
  }

  @observable
  mobileFiltersOpen = false;

  @observable
  items = [];

  @observable
  single = null;

  @observable
  attachments = null;

  @observable
  categories = [];

  @observable
  themes = [];

  @observable
  pagination = {};

  @observable
  defaultQuery = DEFAULT_QUERY;

  @observable
  aggregationsQuery = {
    _queries: ['category', 'theme'],
  };

  @observable
  query = DEFAULT_SEARCH_QUERY;

  @observable
  loading = {
    status: false,
    message: undefined,
  };

  @computed
  get all_categories() {
    return this.categories;
  }

  @computed
  get all_themes() {
    return this.themes;
  }

  @computed
  get search_query() {
    const query = { ...this.defaultQuery, ...this.query };

    return query;
  }

  get aggregations_query() {
    return { ...this.defaultQuery, ...this.aggregationsQuery };
  }

  @computed
  get is_loading() {
    return this.loading.status;
  }

  @computed
  get get_order() {
    return this.query._order;
  }

  @computed
  get get_single() {
    return toJS(this.single);
  }
  @computed
  get get_attachments() {
    return toJS(this.attachments);
  }

  @computed
  get all_documents() {
    return this.items;
  }

  @action
  category_checked = (id) => {
    return this.query.category?.includes(id);
  };

  @action
  setQueryDate = (key, value) => {
    console.group('SET QUERY DATE');
    console.log(key, value, 'SET QUERY DATE');
    console.log('CURRENT QUERY:', toJS(this.query));

    if (!this.query.published && !value) {
      this.query.published = {};
    }

    this.setPage(1);
    this.query.published[key] = value;

    console.log('NEW QUERY:', toJS(this.query));
    console.groupEnd();
  };

  @action
  updateQuery = (query) => {
    this.query = { ...this.query, ...query };
  };

  @action
  setSearchQuery = (searchQuery) => {
    this.query._search = searchQuery;
  };

  @action
  setPage = (page) => {
    this.query._page = page;
    this.pagination.page = page;
  };

  @action
  resetSort = () => {
    const newObject = { ...this.query };
    delete newObject._order;
    this.query = newObject;
  };

  @action
  setSort = (key, value) => {
    console.group('SET SORT');
    console.log(key, value);
    console.log('VALUE', value);
    this.query._order = {};
    this.query._order[key] = value;
    console.groupEnd();
  };

  @action
  toggleSearchArrayValue = (key, value) => {
    console.group('TOGGLE SEARCH ARRAY VALUE');
    console.log(key, value);
    if (!this.query[key]) {
      console.log('KEY DOES NOT EXIST, CREATING ARRAY');
      this.query[key] = [];
    }

    const index = this.query[key]?.indexOf(value);
    // Remove item if we find it in the array.
    if (index !== -1) {
      console.log(index, this.query[key]);
      this.query[key] = this.query[key].filter((cat) => cat !== value);
      return;
    }

    if (key === 'category') {
      this.setPage(1);
    }

    this.query[key] = [...this.query[key], value];
    console.groupEnd();
  };

  @action
  toggleMobileFilters = () => {
    this.mobileFiltersOpen = !this.mobileFiltersOpen;
  };

  @action
  getSearchPageURL = (params = {}) => {
    console.group('GET SEARCH PAGE URL');
    console.log('BUILDING URL, CURRENT QUERY:', toJS(this.query));
    const urlParams = AcBuildURLSearchParams(this.query);
    console.log(urlParams);
    console.groupEnd();
    // console.log('building url');
    return `/zoeken?${urlParams}`;
  };

  @action
  fetchDocuments = async () => {
    this.loading.status = true;
    console.group('MAKING API CALL');
    console.log('SEARCH QUERY:', toJS(this.search_query.published));
    console.groupEnd();

    app.store.api.documents
      .search(this.search_query)
      .then((response) => {
        this.items = response.results;
        this.pagination = response;
        delete this.pagination.results;
      })
      .catch((e) => console.error(e))
      .finally(() => {
        this.loading.status = false;
      });
  };

  @action
  fetchDocument = async (_id) => {
    this.loading.status = true;

    app.store.api.documents
      .single(
        _id,
        new URLSearchParams(
          AcBuildURLSearchParams({ _id, ...this.defaultQuery })
        ).toString()
      )
      .then((response) => {
        this.single = response;
      })
      .catch((e) => console.error(e))
      .finally(() => {
        this.loading.status = false;
      });
  };

  @action
  fetchAttachments = async (_id) => {
    this.loading.status = true;

    app.store.api.documents
      .attachments(
        _id,
        new URLSearchParams(
          AcBuildURLSearchParams({ _id, ...this.attachmentDefaultQuery })
        ).toString()
      )
      .then((response) => {
        this.attachments = response;
      })
      .catch((e) => console.error(e))
      .finally(() => {
        this.loading.status = false;
      });
  };

  @action
  resetDocument = () => {
    this.single = null;
    this.attachments = null;
  };

  @action
  resetSearchQuery = () => {
    this.query = DEFAULT_SEARCH_QUERY;
  };

  @action
  resetAggregations = () => {
    this.categories = [];
    this.themes = [];
  };

  @action
  fetchAggregations = async () => {
    this.loading.status = true;
    app.store.api.documents
      .searchAggregations(this.aggregations_query)
      .then((response) => {
        this.categories = response.facets.category;
        this.themes = response.facets.themes;
      })
      .catch((e) => console.error(e))
      .finally(() => {
        this.loading.status = false;
      });
  };
}

export default DocumentsStore;
